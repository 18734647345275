import React, { useContext, useMemo, useRef, useState } from 'react';
import { AmplienceCategoryLink } from '@Types/amplience/amplienceComponents';
import classNames from 'classnames';
import { LayoutContext } from 'frontastic/provider/layout';
import ArrowIcon from '../../icons/arrow';
import Carousel from '../products/carousel';
import CategoryCard from './category-card';
import styles from './category-carousel.module.scss';

export interface CarouselProps {
  content: {
    categories: AmplienceCategoryLink[];
    title: string;
    colour: string;
    _meta: any;
  };

  variation?: 'page-count';
}

const CategoryCarousel: React.FC<CarouselProps> = ({ content, variation }) => {
  const layout = useContext(LayoutContext);
  const size = variation === 'page-count' ? 'large' : 'default';
  const items = content.categories
    .filter((item) => item.imageLink?.contentImage)
    .map((category, i) => {
      return <CategoryCard data-testid={`category-card${i}`} key={i} category={category} size={size} />;
    });

  const backgroundStyle = useMemo(() => {
    const bgHeightPercentage = layout.isMobile ? '50%' : '60%';

    return {
      background: `linear-gradient(to bottom, ${content.colour} 0%, ${content.colour} ${bgHeightPercentage}, white ${bgHeightPercentage}, white 100%)`,
    };
  }, [content.colour, layout.isMobile]);

  const [currentCount, setCurrentCount] = useState<number>(0);
  const [scrollPercentage, setScrollPercentage] = useState<number>(0);
  const [showButtonLeft, setShowButtonLeft] = useState<boolean>(false);
  const [showButtonRight, setShowButtonRight] = useState<boolean>(true);
  const ref = useRef(null);

  const onScroll = (event) => {
    setCurrentCount(event.currentCount);
    setScrollPercentage(event.scrollPercentage);
    setShowButtonLeft(event.showLeftButton);
    setShowButtonRight(event.showRightButton);
  };

  return (
    <div style={backgroundStyle} className={styles.carouselWrapper} data-testid="category-carousel">
      <div className={styles.heading}>
        <h2 data-testid="category-carousel_heading">{content.title}</h2>
        {variation === 'page-count' && (
          <div className={styles.buttonContainer}>
            <button
              className={classNames(styles.btn, styles.btn_left, {
                [styles.disabledButton]: !showButtonLeft,
              })}
              onClick={() => ref.current.scrollByPage(-1)}
              aria-label="Scroll Left"
            >
              <ArrowIcon className={classNames(styles.svg, styles.svg_left)}></ArrowIcon>
            </button>
            <button
              className={classNames(styles.btn, styles.btn_right, {
                [styles.disabledButton]: !showButtonRight,
              })}
              onClick={() => ref.current.scrollByPage(1)}
              aria-label="Scroll Right"
            >
              <ArrowIcon className={classNames(styles.svg, styles.svg_right)}></ArrowIcon>
            </button>
          </div>
        )}
      </div>
      <Carousel
        ref={ref}
        items={items}
        classNameOverrides={{ items: styles.spacer }}
        showButtons={variation !== 'page-count'}
        onScroll={onScroll}
      />
      {variation === 'page-count' && (
        <div className={styles.countContainer}>
          <div className={styles.progressBarContainer}>
            <div style={{ width: `${scrollPercentage}%`, minWidth: `${100 / items.length}%` }}></div>
          </div>
          <div className={styles.count}>
            {currentCount + 1} / {items.length}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryCarousel;
