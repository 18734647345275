import { useContext } from 'react';
import Image from 'next/image';
import { AmplienceCategoryLink } from '@Types/amplience/amplienceComponents';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';
import NextLink from 'components/common/NextLink';
import { amplienceImageLoader } from 'helpers/imageLoaders/amplienceImageLoader';
import { createImageUrlFromAmplienceImageCard } from 'helpers/utils/amplienceUtils';
import { LayoutContext } from 'frontastic/provider/layout';
import styles from './category-card.module.scss';
interface Props {
  category: AmplienceCategoryLink;
  size?: 'default' | 'large';
}

const CategoryCard = ({ category, size = 'default' }: Props) => {
  const layout = useContext(LayoutContext);

  return (
    <div
      data-testid="category-card_wrapper"
      className={classNames(styles.categoryWrapper, {
        [styles.largeItem]: size === 'large',
      })}
    >
      <div className={styles.categoryImage}>
        {category.imageLink.contentImage && (
          <Image
            src={createImageUrlFromAmplienceImageCard({ contentImage: category.imageLink.contentImage })}
            alt={category.imageLink.metaTextAlternative ?? ''}
            layout="fill"
            loader={({ src, width }) => amplienceImageLoader('General', src, width)}
            sizes="(max-width: 768px) 50vw, 33vw"
            className={styles.categoryImage__image}
            data-testid="category-card_image"
          />
        )}
      </div>
      {category.heading?.length > 0 && <h4 className={styles.heading}>{category.heading}</h4>}
      {category.description?.[0]?.data?.length > 0 && (
        <ReactMarkdown data-testid="category-card_description" className={styles.description}>
          {category.description[0].data}
        </ReactMarkdown>
      )}
      {category.imageLink.link.contentLinkUrl?.length > 0 && (
        <NextLink className={styles.redirect} href={category.imageLink.link.contentLinkUrl}>
          <div data-testid="category-card_redirect"> {category.imageLink.link.contentLinkText}</div>
          <div className={classNames({ [styles.redirect__line]: !layout.isMobile })} />
        </NextLink>
      )}
    </div>
  );
};

export default CategoryCard;
